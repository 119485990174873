<template>
  <div class="wrap">
    <el-button
      class="btn"
      style="margin-bottom: 10px"
      @click="dialogFormVisible = true"
      >筛选</el-button
    >
    <el-dialog width="24%" title="筛选" :visible.sync="dialogFormVisible">
      <el-form :model="form">
        <el-form-item label="用户帐号">
          <el-input style="width: 300px" v-model="form.username"></el-input>
        </el-form-item>
        <el-form-item label="公司名称">
          <el-input
            style="width: 300px"
            v-model="form.license_company_name"
          ></el-input>
        </el-form-item>
        <el-form-item label="审核状态">
          <el-select
            style="width: 300px"
            v-model="form.registration_status"
            placeholder="请选择活动区域"
          >
            <el-option
              v-for="(item, index) in status"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          style="background: #dc513e !important; color: #fff"
          @click="clear"
          >清 空</el-button
        >
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button class="btn" @click="filter">确 定</el-button>
      </div>
    </el-dialog>

    <el-table v-loading="loading" :data="tableData" style="width: 100%">
      <el-table-column
        v-for="(item, index) in list_data"
        :key="index"
        :label="item.title"
        :prop="item.value"
      ></el-table-column>
      <el-table-column label="营业执照">
        <template slot-scope="scope">
          <el-image
            style="width: 50px"
            :src="scope.row.license"
            :preview-src-list="[scope.row.license]"
          >
          </el-image>
        </template>
      </el-table-column>
      <el-table-column label="审核">
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.status == '未审核'"
            type="primary"
            size="mini"
            @click="shenHe(scope.row.id, 2)"
            >通过</el-button
          >
          <el-button
            v-if="scope.row.status == '通过'"
            type="primary"
            size="mini"
            @click="shenHe(scope.row.id, 1)"
            >反审</el-button
          >
        </template>
      </el-table-column>
      <!--
      <el-table-column label="删除">
        <template slot-scope="scope">
          <el-button
            type="primary"
            size="mini"
            @click="mydelete(scope.row.id)"
            class="shanchu"
            >删除</el-button
          >
        </template>
      </el-table-column>
-->
      <el-table-column label="修改营业执照">
        <template slot-scope="scope">
          <el-upload
            class="avatar-uploader"
            action="/api/kapin_ee/user/v1/files_upload/"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
          >
            <el-button type="primary" size="mini" @click="upfile(scope.row.id)"
              >修改</el-button
            >
          </el-upload>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        :page-size="page_size"
        :current-page="current_page"
        layout="prev,pager,next"
        background
        :total="pageTotal"
        @current-change="handleCurrent"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import {
  registerMana,
  filter_regis,
  audit,
  // mydelete,
  upfile,
} from "@/api/hr24_api/api_all.js";
export default {
  data() {
    return {
      pageTotal: 0,
      current_page: 1,
      page_size: 10,
      loading: true,
      tableData: [],
      list_data: [
        { title: "联系人姓名", value: "contact_name" },
        { title: "营业执照公司名", value: "license_company_name" },
        { title: "手机号", value: "username" },
        { title: "地区", value: "area" },
        { title: "注册提交时间", value: "create_time" },
        { title: "审核状态", value: "status" },
      ],
      dialogFormVisible: false,
      status: [
        { label: "未审核", value: "0" },
        { label: "不通过", value: 1 },
        { label: "通过", value: 2 },
      ],
      form: {
        license_company_name: "",
        username: "",
        registration_status: "",
      },
      imageUrl: "",
      license: "",
      update_id: "",
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    //删除该企业
    // mydelete(id) {
    //   this.$confirm("是否删除？", "提示", {
    //     confirmButtonText: "确定",
    //     cancelButtonText: "取消",
    //     type: "warning",
    //   })
    //     .then(() => {
    //       mydelete({ register_id: id }).then((res) => {
    //         console.log(res);
    //         if (res.code == 0) {
    //           this.$message.success(res.msg);
    //           this.filter();
    //         } else {
    //           this.$message.warning(res.msg);
    //         }
    //       });
    //     })
    //     .catch(() => {
    //       this.$message({
    //         type: "info",
    //         message: "已取消删除",
    //       });
    //     });
    // },
    upfile(update_id) {
      this.update_id = update_id;
    },
    //上传营业执照
    handleAvatarSuccess(res, file) {
      console.log("  this.update_id = ", this.update_id);
      this.imageUrl = file.response.file_url;
      this.license = file.response.file_id;
      console.log("  this.imageUrl = ", this.imageUrl);
      console.log("  this.license = ", this.license);

      //更新营业执照
      upfile({ register_id: this.update_id, license: this.license }).then(
        (res) => {
          console.log(res);
          if (res.code == 0) {
            this.$message.success(res.msg);
            this.filter();
          } else {
            this.$message.warning(res.msg);
          }
        }
      );
      this.update_id = "";
    },

    shenHe(id, status) {
      this.$confirm(
        "" + status == 1
          ? "此操作将驳回注册请求"
          : "此操作将通过注册请求" + ", 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          audit({ register_id: id, registration_status: status }).then(
            (res) => {
              console.log(res);
              if (res.code == 0) {
                this.$message.success(res.msg);
                this.filter();
              } else {
                this.$message.warning(res.msg);
              }
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消审核",
          });
        });
    },
    init() {
      let search_data = {};
      search_data["page"] = this.current_page;

      registerMana({ search_data }).then((res) => {
        if (!res.code) {
          res.data.register_list.map((item) => {
            item.area = item.city_name + "-" + item.district_name;
            item.status =
              item.registration_status == 0
                ? "未审核"
                : item.registration_status == 1
                ? "不通过"
                : "通过";
          });
          this.loading = false;

          console.log(res);
          this.pageTotal = res.all_count;

          this.tableData = res.data.register_list;
        }
      });
    },

    handleCurrent(val) {
      console.log(val);
      this.current_page = val;

      this.init();
    },

    clear() {
      this.dialogFormVisible = false;
      this.form = {
        license_company_name: "",
        username: "",
        registration_status: "",
      };
    },

    filter() {
      this.loading = true;
      this.dialogFormVisible = false;
      let search_data = {};
      for (let i in this.form) {
        if (this.form[i] != "") {
          search_data[i] = this.form[i];
        }
      }
      search_data["page"] = this.current_page;

      filter_regis({ search_data }).then((res) => {
        if (!res.code) {
          res.data.register_list.map((item) => {
            item.area = item.city_name + "-" + item.district_name;
            item.status =
              item.registration_status == 0
                ? "未审核"
                : item.registration_status == 1
                ? "不通过"
                : "通过";
          });
          this.loading = false;

          this.pageTotal = res.all_count;

          this.tableData = res.data.register_list;
        }
      });
    },
  },
};
</script>

<style scoped>
.wrap {
  padding-top: 54px;
}
.pagination {
  width: 100%;
  margin-top: 10px;
  text-align: center;
}
.shanchu {
  background-color: #ff0000;
  border-color: #ff0000;
}
</style>
